b,
label,
span,
i,
p {
  font-size: 9px !important;
}

/* body {
  background: none;
} */
