
.titulo{
  font-size: 9px !important;
  font-weight: bold ;
}


.expedition{
    font-size: 17px !important;
}
.factura{
    font-size: 15px !important;
}