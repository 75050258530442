@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: extlt;
  src: url(../src/assets/fonts/CabinetGrotesk-Light.otf);
}

@font-face {
  font-family: medium;
  src: url(../src/assets/fonts/CabinetGrotesk-Medium.otf);
}

@font-face {
  font-family: regular;
  src: url(../src/assets/fonts/CabinetGrotesk-Regular.otf);
}

@font-face {
  font-family: thin;
  src: url(../src/assets/fonts/CabinetGrotesk-Thin.otf);
}

.font-family-thin {
  font-family: thin;
}

.font-family-extlt {
  font-family: extlt;
}

#root {
  height: 100%;
  overflow: hidden;
}

.mobile-menu {
  background-color: rgb(22, 29, 66) !important;
  width: 175px;
}

a.active {
  font-weight: bold;
}

.mobile-menu p,
.mobile-menu svg,
.mobile-menu div {
  color: white !important;
  font-size: 13px !important;
}

.text-opaque {
  color: #ccc !important;
}

.mobile-menu .active p,
.mobile-menu .active svg {
  font-weight: 600;
  color: #d18225 !important;
}

* {
  margin: 0;
  padding: 0;
  /* font-family: regular; */
  font-family: Arial, Helvetica, sans-serif;
  scroll-behavior: smooth;
  font-size: 11px;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: none;
  border-color: transparent;
}

body {
  /* background-image: linear-gradient(to bottom, #1c2f51, #060a2d); */

  /* background-color: #060a2d; */
  /* background-color: white; */
  background: linear-gradient(160deg, #060a2d, #1c2f51);
  /* background: linear-gradient(217deg, #274472, rgba(0, 0, 0, 0) 70.71%),
    linear-gradient(127deg, #5885af, rgba(0, 0, 0, 0) 70.71%),
    linear-gradient(336deg, #41729f, rgba(0, 0, 0, 0) 70.71%); */
  /* background-size: 400% 400%;

  -webkit-animation: bg 7s ease infinite;
  -moz-animation: bg 7s ease infinite;
  animation: bg 7s ease infinite; */

  cursor: url("../src/assets/img/cursor.png"), auto;
  /* background: linear-gradient(to top, #060a2d, #1c2f51);
  background-size: 120% 120%;
  animation: gradient-animation 5s ease infinite; */
}

/* @keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} */

.animated {
  animation: anim 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 100ms;
}

.animated2 {
  animation: anim 0.5s;
  animation-fill-mode: forwards;
}

b,
label,
span,
p {
  font-size: 11px;
}

@keyframes anim {
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
}

@-webkit-keyframes bg {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}
@-moz-keyframes bg {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}
@keyframes bg {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}

b {
  font-weight: 800;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 30px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 40px;
  background: lightgray;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 40px;
  background: #1c2f51 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #151638;
}

.primary-color-bg-gradient {
  background-image: linear-gradient(to bottom, #1c2f51, #060a2d);
  /* background-color: rgb(0, 0, 45); */
}

.font-white {
  color: white;
}

/* button {
  background-color: gray;
  color: white !important;
  padding: 3px;
  font-size: 12px !important;
} */

.font-black {
  color: black !important;
  font-family: regular;
}

.primary-color-bg {
  background-color: rgb(22, 29, 66);
}

.primary-color {
  color: rgb(22, 29, 66) !important;
}
.secondary-color {
  color: #1c2f51 !important;
}
.secondary-bg-color {
  background-color: #1c2f51 !important;
}
.ternary-bg-color {
  background: white;
  color: #060a2d !important;
}

/* ::-webkit-calendar-picker-indicator {
  filter: invert(1);
} */

/* button:hover:not([type="button"]) {
  background-color: gray !important;
  color: white !important;
} */

select option {
  color: #060a2d;
}

.input-bg-color {
  /* background-color: rgba(0, 0, 45, 0.7); */
  background-color: rgb(88, 111, 153, 1);
  color: white;
  font-size: 11px !important;
  border-color: rgba(0, 0, 0, 0.3);
  /* text-transform: uppercase; */

  /* border-radius: 50px; */
}
.input-bg-color-custom {
  /* background-color: rgba(0, 0, 45, 0.7); */
  background-color: rgb(88, 111, 153, 1);
  color: white;
  font-size: 11px !important;
  border-color: rgba(0, 0, 0, 0.3);
  /* text-transform: uppercase; */
  border-bottom: 1px solid lightgray !important;
  border-top: none !important;
  /* border-radius: 50px; */
}


.input-bg-color-inversed {
  background-color: white !important;
  color: rgba(0, 0, 45, 0.7) !important;
}

.input-bg-color::placeholder {
  color: rgba(255, 255, 255, 1);
}

.input-bg-color-inverted {
  background-color: #ccc;
  color: rgba(0, 0, 45, 0.7);
}

input:not([disabled]),
input:not([readonly]),
select:not([disabled]),
select:not([readonly]) {
  background-color: white;
  color: #060a2d;
  border: 1px solid lightgray;
}

input:not([disabled])::placeholder,
input:not([readonly])::placeholder,
select:not([disabled])::placeholder,
select:not([readonly])::placeholder {
  color: #060a2d;
}

input[disabled]::placeholder,
input[readonly]::placeholder,
select[disabled]::placeholder,
select[readonly]::placeholder {
  color: #060a2d;
}

input[disabled],
input[readonly],
textarea[disabled],
select[disabled] {
  /* background-color: rgb(88, 111, 153, 0.4); */
  background-color: rgb(230 230 230) !important;
  color: black !important;
}

/* select option {
  color: white;
} */

.container {
  width: 100% !important;
  max-width: 100% !important;
}

text {
  fill: #1c2f51 !important;
}

.another-text-white {
  color: white !important;
}

.text-white {
  color: #1c2f51 !important;
}

header a.active {
  font-weight: bold;
}

aside {
  width: 5%;
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;
  padding: 5px;
}

aside ul::-webkit-scrollbar,
aside::-webkit-scrollbar {
  width: 0px;
}

aside ul li {
  color: white;
}

aside ul li:hover {
  color: #f2912a;
}

main {
  width: 95%;
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
  background-color: white;
  box-shadow: 0 0.02rem 10px rgba(0, 0, 0, 0.4);
  /* filter: opacity(0.7);
  backdrop-filter: blur(5px); */
}

h1 {
  font-size: 30px;
}

main h1,
main h2:not(.date),
table thead tr th {
  color: rgb(0, 0, 45);
}
table thead tr th {
  font-weight: bold;
  text-transform: uppercase;
}

/* table tr td {
  padding: 3px !important;
} */

/* table:not(.sales-table):not(.modal table):not(.clients-table):not(.users-table):not(.providers-table):not(.collections-table):not(.sales-report-table):not(.rg90-table):not(.accounts-table):not(.support-table),
.pagination-container:not(.collections-pagination-container):not(.clients-pagination-container):not(.providers-pagination-container):not(.users-pagination-container):not(.menus-pagination-container):not(.support-pagination-container):not(.accounts-pagination-container) {
  position: relative;
  top: -25px;
} */

/* table:not(.sales-table):not(.modal table) tbody tr:first-child td:first-child {
  border-radius: 15px 0 0 0;
}

table:not(.sales-table):not(.modal table) tbody tr:first-child td:last-child {
  border-radius: 0 15px 0 0;
} */

table.sales-table thead tr th {
  color: rgb(0, 0, 45);
}

table.sales-table tbody .input-bg-color,
.sales-modal .input-bg-color {
  font-size: 12px;
}

table tbody tr td {
  font-weight: 400;
}

table tbody tr {
  transition: all 0.5s;
}

table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.5s;
}

aside:hover {
  width: 20%;
}

aside img {
  transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

aside:hover img {
  width: 120px !important;
  transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

aside:hover p,
aside:hover .menu-chevron {
  display: block;
}
aside:hover .submenu-item-container {
  align-items: flex-start;
}

/* aside li:hover p,
aside a.active li p {
  font-weight: bold;
} */

aside ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px auto;
  text-wrap: nowrap;
  font-size: 14px;
  width: 100%;
  padding-left: 40%;
}

aside ul a.active li {
  font-weight: bold;
  color: #f2912a;
}

aside:hover ~ main {
  width: 86%;
}

aside:hover ul li {
  padding-left: 12% !important;
}

.support-table tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25) !important;
}

table:not(.collections-table) {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

/* table tbody tr:not(:last-child) {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.3);
} */

table thead tr th,
table tbody tr td {
  padding: 1px;
  text-align: center;
  /* text-wrap: nowrap; */
  overflow: hidden;
}

table
  tbody:not(.pricesTable tbody):not(.collections-table tbody):not(
    .sales-report-table tbody
  ) {
  padding: 10px;
}

.pricesTable tbody tr td {
  padding: 10px !important;
}
.pricesTable tbody tr td {
  padding-bottom: 20px !important;
}

.pricesTable tbody tr:first-child:not(.priceSecondTable tbody tr:first-child) {
  border-top: 2px solid black;
  margin-top: 10px;
}

.modal-bg-color {
  background-color: rgb(88 111 153 / var(--tw-bg-opacity));
  backdrop-filter: blur(2px);
}

.menu p {
  font-size: 13px !important;
}

.user-menu-open {
  opacity: 1;
  z-index: 9;
  transition: all 0.7s;
}

td {
  max-width: 100px;
  max-height: 100px;
  overflow: hidden;
}

.user-menu-closed {
  opacity: 0;
  z-index: -1;
  transition: all 0.7s;
}

.dashboard-container div {
  width: 45%;
  display: inline-block;
  margin: 15px;
}

rect {
  fill-opacity: 1;
  background-color: transparent;
}

[class*="axisReferenceVisualsFloor"] rect {
  fill-opacity: 0 !important;
}

.combobox-option {
  border-radius: 0 !important;
}
.combobox-option:hover span {
  color: white;
}

.table {
  width: 100%;
}

.table .thead,
.table .tbody {
  width: 100%;
}

.table .thead .tr {
  width: 100%;
  font-weight: 600;
}

.table .thead .tr,
.table .tbody .tr,
.table .tbody .td {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  text-align: center;
  /* background: white; */
}

.table .tbody .tr {
  justify-content: space-between;
}

textarea {
  /* border-radius: 20px !important; */
  background-color: white;
  border: 1px solid gray;
  height: 135px;
}

@media screen and (max-width: 1068px) {
  aside {
    width: 7.5%;
  }
}

@media screen and (max-width: 768px) {
  .dashboard-container div {
    width: 90%;
  }

  aside {
    display: none;
  }

  .title {
    display: none;
  }

  .logo-top-mobile {
    display: block;
  }

  .company-name-mobile {
    display: block;
  }

  main {
    width: 100%;
    height: 89vh;
    margin-top: 15px;
  }

  .mobile-user-menu li {
    display: flex;
  }
}

@media screen and (max-width: 468px) {
  .title {
    /* margin-top: 25px; */
  }
  .title-button {
    top: -70px;
  }
  .sales-buttons-container {
    /* padding-left: 190px;
    padding-right: 40px; */
  }
}

@media screen and (min-height: 800px) {
  main {
    height: 90.5vh !important;
  }
}

@media screen and (max-width: 1140px) {
  .support-buttons-container {
    display: none;
  }
}
/*  */
#boxreceiptNumber{
  width: 235px  !important;
}
#boxstamped{
  width: 271px  !important;
}
#boxnumber{
  width: 227px !important;
}
#boxexpeditionPoint{
  width: 161px !important;
}
#boxreceiptExpeditionPoint{
  width: 171px !important;
}
#boxcompanyId{
  width: 274px !important;
}
#boxbranchId{
width: 269px !important;
}
#boxname{
  width: 279px !important;
}
#boxactive{
  width: 40px !important;
}
#boxopen{
  width: 40px !important;
}
#boxdeleted{
  width: 40px !important;
}
.bt-mobi
{
  background:white;
 color:rgb(9 15 50);

}
.bt-mobi-se
{
  background:rgb(9 15 50);
 color:white !important;


}
@media (max-width: 370px) {
  .campototal{
    width: 60px !important;
  }
  .campototal2{
    width: 70px !important;
  }
}
@media (max-width: 450px) {
  .campototal{
    width: 100px !important;
  }
  .campototal2{
    width: 115px !important;
  }
}
@media (max-width: 500px) {
  .campototal{
    width: 120px !important;
  }
  .campototal2{
    width: 125px !important;
  }
}
@media (max-width: 450px) {
  .vw-xs{
    height: 128px;
  }

}
